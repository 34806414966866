import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import imgc1 from "../images/img-c1.png";


const PortfolioPage = () => (
  <Layout>
    <SEO 
      title="Javier Suzuki | Design & Front-end Development Portfolio - Modern Brands Sites Campaigns"
      keywords={[`javier`, `suzuki`, `web`, `ui`, `ux`, `design`, `front-end`, `development`, `marketing campaign`, `landing page`]}
    />
    <div className="portfolio-header">
      <h1>Modern Brands</h1>
      <h5>Marketing Campaigns Updates</h5>
      <p>Updates of marketing campaigns for brands of the group.</p>
    </div>
    <div className="portfolio-footer">
      <a className="btn btn-primary" href="/" target="_blank" rel="noopener noreferrer">View Project</a>
    </div>
    <div className="portfolio-content">
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Design & Development of Marketing Blog</figcaption> */}
        <img src={imgc1} alt=""/>
      </figure>
    </div>

  </Layout>
)

export default PortfolioPage
